<template>
  <div class="dialogVisible">
    <el-dialog title="" :visible.sync="show" width="30%" :show-close="false" @open="opened" @close="handleClose">
      <div class="header">
        <span>导入文件</span>
        <i class="el-icon-circle-close" @click="show = false"></i>
      </div>
      <div class="body">
        <div>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="上传人">
              <div>{{form.nickname}}</div>
              <!-- <el-select v-model="form.region" placeholder="请选择活动区域" size="small">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="上传文件">
              <el-upload class="upload-demo" ref="upload" :before-upload="beforeUpload" :data="uploadData" drag :action="action" :limit="1" :on-success="onSuccess" :show-file-list="true">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或点击上传
                </div>
                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="save-btn">
          <div class="save" @click="confirm">确定</div>
          <div class="cancel" @click="show = false">取消</div>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import { createTopic } from "@/api/topic.js"
export default {
  data () {
    return {
      action: '',
      show: false,
      form: {
        nickname: ''
      },
      uploadData: {
        user_id: localStorage.getItem("user_id"),
        user_token: localStorage.getItem("user_token")
      },
      topicList: [],
      errList: [],
    }
  },
  created () {

  },
  methods: {
    onSuccess (response, file, fileList) {
      this.errList = []
      this.topicList = response.data.list
    },
    handleClose () {
      this.$refs.upload.clearFiles()
      this.errList = []
    },
    confirm () {
      if (this.topicList.length < 1) {
        this.$message.error('没有可导入的题目！')
        this.$refs.upload.clearFiles()
        return
      }

      let promise = this.topicList.map(v => {
        return createTopic(v)
      })
      Promise.allSettled(
        promise
      ).then(res => {
        res.map((v, i) => {
          if (v.status == "rejected") {
            this.errList.push({
              title: this.topicList[i].title,
              msg: v.reason.msg
            })
          }
        })
        //有题报错 没导入成功
        if (this.errList.length > 0) {
          let str = ''
          this.errList.map(v => {
            str += `<div style="line-height: 24px;margin: .7rem 0;">
          <div>题目名称：${v.title || ''}</div>
          <div>错误信息：${v.msg || ''}</div>
          <div>`
          })
          this.$alert(str, '题目导入出错', {
            dangerouslyUseHTMLString: true
          })
        }
        this.show = false
        this.$parent.getTopicList()
      })
    },
    opened () {
      this.action = this.$store.state.tmAction
      this.getUserInfo()
    },
    // 获取用户信息
    getUserInfo () {
      this.$http.post('/xapi/user/getInfo').then(({data})=>{
        this.form.nickname = data.nickname
        this.$store.commit('SET' , {key:'userInfo' ,value:Object.assign({} , this.userInfo , data)})
        this.$store.commit("SET" , {key:"userType" , value:data.type})
        this.$store.commit("SET" , {key:"user_id" , value:data.id})
        localStorage.setItem("type", data.type)
      }).catch()
    },
    beforeUpload (file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是 xls、xlsx格式!',
          type: 'warning'
        })
        return false
      }
    }
  },
};
</script>

<style lang="less" scoped>
.dialogVisible {
  .upload-demo {
  }
  ::v-deep {
    .el-upload {
      width: 100%;
    }
    .el-upload-dragger {
      width: 100%;
    }
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .header {
    height: 35px;
    background: #00957e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    line-height: 35px;
    align-items: center;
    .el-icon-circle-close {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .body {
    padding: 2rem 3rem;

    .body-item {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      .select {
        display: flex;
        justify-content: space-between;
        > div {
          width: 48%;
        }
      }
    }
    .body-item-textarea {
      margin-top: 0.5rem;
      ::v-deep {
        .el-textarea__inner {
          outline-color: #979797; // 鼠标聚焦边框颜色
        }
        .el-textarea__inner:focus {
          border-color: #979797;
        }
      }
    }
    .save-btn {
      height: 2rem;
      margin-top: 1rem;
    }
    .save,
    .cancel {
      float: right;
      bottom: 2rem;
      right: 2rem;
      width: 40px;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      padding: 6px 20px;
      text-align: center;
    }
    .cancel {
      background: #fff;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 2px;
      border: 1px solid #979797;
      padding: 5px 20px;
      margin-right: 0.5rem;
    }
  }
}
</style>